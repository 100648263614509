
.footer-container {
   /* background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(59, 31, 144) 100%);
   */
   background:rgb(11, 7, 24);
   padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
   /* align-items: center;
   */ 
   width: 100%;
  }
  
  .footer-subscription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  
    margin-bottom: 24px;
    padding: 24px;
    color: #fff;
  }
  
  .footer-subscription > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
  }
  
  .footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
  }
  
  .footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
  }
  
  .footer-links {
    width: 100%;
    
    display: flex;
    
  }
  .footer_logo {
    color:white;
    font-size: 32px;
    text-decoration: none;
  }
  .footer-link-wrapper {
    display: flex;
    width: 100%;
    color:white;

  }

  .first_footer {
    width: 25%;
    margin-left: 10%;
    margin-right: 5%;
  }
  .first_footer p {
    margin-top: 10px;
    line-height: 1.6;
    font-size: 17px;
  }
  .first_footer img {
    background: none;
    height: 18px;
    border: 2px solid white;
    padding: 13px 13px 13px 13px;
    border-radius: 50%;
    cursor: pointer;
  }
  .first_footer img.insta {
    height: 38px;
    padding: 3px;
    margin-left: 20px; 
  }
  .first_footer img:hover {
    background: rgb(59, 31, 144);
    border:none;
    height: 22px;
  }
  .first_footer img.insta:hover {
    background: linear-gradient(135deg, #f58529, #dd2a7b, #8134af, #515bd4);
    border:none;
    height: 42px;
  }
  .socials-icons {
    display:inline-flex;
    margin-top: 20px;

  }
  
  .footer-link-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px;
    text-align: left;
    width: 25%;
    box-sizing: border-box;
    margin-left: auto;
  }
  .footer-link-items Link {
margin-top: 28px;
  }

  .contact_section {
    display: inline-flex;
  }
  .contact_section img {
    height: 20px;
    border: 2px solid white;
    border-radius: 50%;
    padding: 8px;

  }
  .contact_section p {
    margin-left: 14px;
    margin-top: 10px;
  }
  
  .footer-link-items h2 {
    margin-bottom: 16px;
  }
  
  .footer-link-items > h2 {
    color: #fff;
  }
  
  .footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  
  .footer-link-items a:hover {
    color: #e9e9e9;
    transition: 0.3s ease-out;
  }
  
  .footer-email-form h2 {
    margin-bottom: 2rem;
  }
  
  .footer-input::placeholder {
    color: #b1b1b1;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
  .social-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }
  
  @media screen and (max-width: 900px)
  {
    .footer-link-wrapper {
      display : block;
    }

    .footer-link-items {
      
      margin-left: auto;      
      margin-right: auto;
      margin-top: 40px;
      width: fit-content;
    }
    .footer-link-items h2 {
      margin-left: auto;
      margin-right: auto;
    }
    .footer-link-items a {
      margin-left: auto;
      margin-right: auto;

    }

    .first_footer p{
    width: 90%;
    margin-left: 5%;
    }

    .first_footer {
      width: fit-content;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    /*
    .btn {
      width: 100%;
    }
  
    /*
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    */
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 768px) {
  }



  .footer-link-items a {
    color: #fff; /* Set text color to white */
    text-decoration: none; /* Remove underline */
    display: flex; /* Ensure proper alignment of content */
    align-items: center; /* Align items vertically */
  }