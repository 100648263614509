.responsive_item {
  display: none;
}


.cards {
    padding:0;
    padding-top: 30px;
    background: #fff;
    margin-top: 86vh;
    width: 100%;
  }
  
 
  
  .cards__container {
    display: flex;
    flex-flow: column;
    align-items: center;
    
    width: 100%;
    margin: 0 auto;
  }
  
  .cards__wrapper {
    position: relative;
    margin: 50px 0 45px;
    width: 100%;
    display:inline-block;
  }
  
  .cards__items {
    margin-bottom: 24px;
    
  }
  
  li.cards_item {
    width: 44%;
    margin-left: 4%;
    float:left;
    list-style: none;
    margin-bottom: 30px;
  }
  .cards__item {
   
    display: flex;
    flex: 1;
    margin: 0 1rem;
    border-radius: 10px;
  }
  
  .cards__item__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
  }
  
  .cards__item__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
  }
  
  .fade-img {
    animation-name: fade-img;
    animation-duration: 2s;
  }
  
  /*
  .cards__item__pic-wrap::after {
    content: attr(data-category);
    position: absolute;
    bottom: 0;
    margin-left: 10px;
    padding: 6px 8px;
    max-width: calc((100%) - 60px);
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    background-color: #1f98f4;
    box-sizing: border-box;
  }
  */
  .cards__item__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.2s linear;
  }
  
  .cards__item__img:hover {
    transform: scale(1.1);
  }
  
  .cards__item__link:hover .cards__item__info
  {
    
    padding: 0;
    transition: height 0.5s ease;
    height:100%;
    
    transition: 1s;
  
    }
  
  .cards__item__info {
    padding: 0px;
    position: absolute;
    color: white;
    bottom: 0;
    background: rgba(10, 20, 26, 0.5);
    width: 100%;
    height: 50px;
    text-align: center;

  }
  
  .cards__item__info h1 {
    color:white;
    
  }
  
  .read_more {
    font-weight: bold;
    padding: 15px 20px 15px 20px;
    border-radius: 8px;
    margin-top: 20px;
    font-size: 20px;  
    background:rgb(59, 31, 144);
    color: white;
    border:none; 
    cursor: pointer;
    border-bottom: 4px solid rgb(38, 0, 99);
    transition: 1s;
    
  }
  
  .read_more:hover {
    background: white;
    font-weight: bold;
    color: rgb(59, 31, 144);
    border-bottom: 0px solid white;
    transition: 1s;
  }
  
  .cards__item__link:hover .cards__item__text
  {
    padding-top: 40px;
    transition: 1s;
  }

  .cards_item_details {
    padding-top: 30px;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    
  }
  .cards__item__link:hover .cards__item_details {
    height: 100%;
    display:block;
  }
  .cards__item__text {
   /* color: #252e48;
   */ 
   font-size: 20px;
    line-height: 24px;
    padding-top: 10px;

    
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1300px)
  {
    
    .read_more{
      margin-top: 10px;
    }
  }

  @media only screen and (min-width: 761px) and (max-width: 870px)
{
  .cards_item_details 
  {
    padding-top:15px;
  }
  .read_more{
    margin-top: 10px;
  }


}

@media only screen and (max-width: 434px)
{

  .cards__item__link:hover .cards__item__text
  {
    padding-top: 15px;
  }
  
  .cards_item_details
  {
    padding-top:14px;
    font-size: 14px;
  }

  .read_more
  {
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 16px;
  }

}

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }

    li.cards_item {
        width: 30%;
        margin-left: 2.5%;
        float:left;
        list-style: none;
      }
  }


  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }

 
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }


   
      li.cards_item {
        display: none;
      }

      .cards__item__link {
        display: none;
      }
      


      .responsive_item {
        display: inline;
        width: 44%;
        overflow: hidden;
        float: left;
        height: 300px;
        border-radius: 10px;
        margin-left: 4%;
        margin-bottom: 4vw;
        overflow: hidden;
      }
      .responsive_item img {
        width: 44%;
        height: 300px;
        position: absolute;
        border-radius: 10px;
      }
      .back_color{
        width: 44%;
        height: 300px;
        border-radius: 10px;
        position: absolute;
        background: rgb(0,0,0,0.4);
      }
      .responsive_title {
        position: relative;
        text-align: center;
        z-index: 10;
        color:white;
        font-size: 20px;
        line-height: 24px;
        padding-top: 20px;
        margin-left: auto;
      }
      .responsive_details {
        position: relative;
        text-align: center;
        z-index: 10;
        color: white;
        margin-top: 20px;
        height: 100px;
        padding-left: 10px;
        padding-right: 10px;
      }

      .responsive_read{
        position: relative;
        margin-left: auto;
        width: 50%;
        margin-left: 25%;
        background: #3b1f90;
    border: none;
    border-bottom: 4px solid #260063;
    border-radius: 8px;
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    margin-top: 20px;
    padding: 15px 20px;
    font-weight: bold;
      }
      .responsive_read a {
        color: #fff;
        font-weight: bold;
        text-decoration: none;
        height:100%;
      }

      .cards__container {
        display: inline-block;
    margin: 50px 0 45px;
    position: relative;
    width: 100%;
      }
     
  
  }

@media only screen and (max-width: 760px)
{
    li.cards_item {
        width: 70%;
        margin-left: 15%;
        float:left;
        list-style: none;
      }

      .responsive_item {
        width: 70%;
        margin-left: 15%;
      }
      .responsive_item img {
        width: 70%;
        
      }
      .back_color{
        width:70%;
      }
}



@media only screen and (max-width: 600px)
{
    li.cards_item {
        width: 80%;
        margin-left: 10%;
        float:left;
        list-style: none;
      }

      .responsive_item {
        width: 80%;
        margin-left: 10%;
      }

      .responsive_item img {
        width: 80%;
        
      }
      .back_color{
        width:80%;
      }
}

@media only screen and (max-width: 500px)
{
  
    li.cards_item {
        width: 90%;
        margin-left: 5%;
        float:left;
        list-style: none;
      }

      .responsive_item {
        width: 90%;
        margin-left: 5%;
      }

      .responsive_item img {
        width: 90%;
        
      }
      .back_color{
        width:90%;
      }

}

