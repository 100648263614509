:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20px; 
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #000;
    margin-left: 20px;
    border: 1px solid var(--primary);
    font-weight: bolder;
}
.btn--primary:hover {
    background: black;
    color: white;
}

.btn--outline {
    background-color: rgb(10,5,26);
    color: #fff;
    font-weight: bolder;
    padding: 15px 20px;
    border-radius: 6px;
    font-size: 18px;
   /* border: 1px solid var(--primary);
   */ 
   transition: all 0.3s ease-out;
}

.btn--outline:hover {
    background: white; 
    color: rgb(10,5,26);
}

.btn-medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 20px 40px; 
    font-size: 20px;
    border-radius: 6px;
}

.btn-medium:hover, .btn--large:hover{
    background: #fff;
    color: #242424;

    transition: all 0.3s ease-out;
}

@media only screen and (max-width: 560px)
{
  
.btn {
    /*
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    padding: 14px;
    font-size: 18px;
    
    */

    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 15px;
    padding-left: 10px;
    padding-right: 10px;
    
}
.btn--primary {
    margin-left: 10px;
}

}



@media only screen and (max-width: 400px)
{
  
.btn {
    /*
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    padding: 14px;
    font-size: 18px;
    
    */

    font-size: 17px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 15px;
    padding-left: 10px;
    padding-right: 10px;
    
}
.btn--primary {
    margin-left: 10px;
}

}

@media only screen and (max-width: 360px)
{
  
.btn {
    /*
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    padding: 14px;
    font-size: 18px;
    
    */

    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 15px;
    padding-left: 10px;
    padding-right: 10px;
    
}
.btn--primary {
    margin-left: 10px;
}

}



.btns a{
    text-decoration: none;
}

.btns {
    background: #000;
}