.contact_quote{
    width: 100%;
    text-align: center;
    background:rgb(11, 7, 24);
    padding-bottom: 50px;
    padding-top: 50px;
    margin-top: 0px;

}
.contact_quote p {
    color: white;
    padding-top: 50px;
    font-size: 26px;
    margin-bottom: 0px;
    font-weight: bold;
}
.contact_quote input {
    width: 87%;
    
    padding-left: 3%;
    height: 50px;
    margin-top: 20px;
    font-size: 16px;
    border-radius: 5px;
}
.contact_quote textarea {
    width: 84%;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 20px;
    margin-top: 20px;
    height: 100px;
    font-size: 16px;
    border-radius: 5px;
}

.contact_quote button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px; 
    padding: 10px;
    font-size: 16px;
    color: rgb(11, 7, 24);
    font-weight: bold;
    padding: 20px 30px 20px 30px;
    border-radius: 10px;
    background: white;    
}