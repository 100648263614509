
.container {
    position: relative;
    height: 81vh;
    width: 100%;
    overflow: hidden;
    
  }
  
  .content {
    position: relative;
    z-index: 1;
    padding: 20px;
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(59, 31, 144) 100%); /* Background color */
    height: 100vh;
  }
  .content-text {
    width: 45%;
    line-height: 1.6;
    font-size: 16.5px;
    margin-top: 20px; 
    
  }

  .content p, h1{
    color: white;
    text-align: start;
    padding-left: 20px;

}
.content h1{
    margin-top: 16vh;
    font-size: 46px;
}
  
  .diagonal-image {
    position: absolute;
    top: 0;
    right: 0;
width: 50vw;
    height: 100vh;
    /*background: url('C:\Users\rober\construction-website\public\epoxy-flooring1.jpeg') no-repeat;
   */
       background-size: cover;

    clip-path: polygon(0 100%, 100% 100%, 100% 0, 50% 0);    z-index:10;
}

.service-description {
    display:inline-flex;
    height: 90vh;
    background: #f1f0f0;
    padding-top: 10vh;
    padding-bottom: 40px;
}
.service-description img {
    height: 80vh; 
    width: 40vw;
    margin-right: 3vw;
}

.content_buttons {
  
  padding-top: 16px;
  padding-bottom: 16px; 
  padding-left: 35px;
  padding-right: 35px;
  font-size: 16px;
  margin-left: 20px;
  font-weight: bold;
  color: rgb(212, 175, 55);
  background: none;
  border-radius: 0px;
  border:2px solid rgb(142, 115, 28);
  cursor: pointer;
  border-bottom: 5px solid rgb(142, 115, 28);
}

.content_buttons:hover {
  background: rgb(142, 115, 28);
  border-bottom: 2px solid rgb(142, 115, 28);
  color: white;
  transition: 0.5s;
}

.button_container {
  margin-top: 20px;
  display: inline-flex;
}

.muti {
  margin-top: 26px;
  margin-left: 20px;
  margin-right: 30px;
  font-size: 17.5px;
  color: rgb(90,90,90);
  line-height: 1.6;
}

.description_title {
font-size: 40px;
margin-top: 10px;
margin-left: 20px;
font-weight: bold;
color: rgb(121, 93, 2);
}


@media screen and (max-width: 1050px){
.muti {
  line-height: 1.4;
}

}

@media screen and (max-width: 1000px) {
.content h1 {
  font-size: 40px;
}

.muti {
  line-height: 1.4;
}
}

@media screen and (max-width: 900px) {
  .content h1 {
    font-size: 36px;
  }
  .content-text {
    font-size: 16px;
  }
.button_container {
  display:block;
  width: 270px; 

}
.content_buttons{
  margin-top: 20px;
  width: 230px;
  padding-left: 0;
  padding-right: 0;
}

.muti {
  font-size: 16px;
}
}

@media screen and (max-width: 800px) {
  .content h1 {
    font-size: 32px;
    margin-top: 12vh;
  }
  .content-text {
    line-height: 1.4;
    font-size: 15.5px;
    width: 50%;
  }

  .muti {
    margin-top: 10px;
    font-size: 15.5px;
    text-align: center;
  }
  .description_title {
    font-size: 30px;
    margin-top: 0;
    text-align: center;
    margin-left: 0;
  }

  .service-description {
    display: block;
    height: fit-content;
  }
  .service-description img {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    height: fit-content;
  }
}

@media screen and (max-width: 700px) {
.content h1 {
  margin-top: 10vh;
}

}

@media screen and (max-width: 650px) {
  .content h1 {
    margin-top: 8vh;
  }
}
@media screen and (max-width: 600px) {
  .content h1 {
    margin-top: 3vh;
    font-size: 27px;
  }
  .content-text{
    font-size: 15px;
  }
  .content_buttons {
    margin-top: 10px;
  }


  .service-description img {
    width: 96%;
    margin-left: 2%;
    margin-right: 2%;
    height: 300px;
  }
}

@media screen and (max-width: 500px) {
  .content h1 {
    font-size: 25px;
    width: 50%;
    text-align: center;
  }
  .content-text{
    line-height: 1.2;
    width: 55%;
  }
  .content_buttons {
    width: 200px;
    font-size: 16px;
    
  }

  .content{
    padding-left: 10px;
  }
}

@media screen and (max-width: 450px) {
  .content_buttons {
    width: 180px;
    font-size: 13px;
  }

  .content-text {
    text-align: center;
  }
  .content p {
    text-align: center;
  }
  .content {
    padding-left: 0px;
  }
  .content_buttons {
    width: 160px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}


.button_container a {
  color: rgb(212, 175, 55);
  text-decoration: none;
}