.responsive_hero {
    display:none;
}
video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    pointer-events: none;
}




.hero-container {
    /*
    background: url('images/img-home.jpg') center center/cover no-repeat;
*/
    position: absolute;
    top: 0;
    height: 90vh;

    width: 100%;
    display: flex;
    flex-direction:  column;
    align-items: start;
    
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
    overflow: hidden;
}

.back {
    margin-top: 18vh;
    background: rgba(10, 5, 26, 0.7);
    margin-left: 4%;
    border-radius: 5px;
    padding: 0px 0px 10px 0px ;

}
.hero-container h1{
    color: rgb(255, 255, 255);
    font-family: Arial, Helvetica, sans-serif;
    font-size: 44px;
    font-weight: bold;
    padding: 30px 20px 20px 20px;
    border-radius: 5px;
    width: 46vw;
    text-align: start;
}
.hero_services {
    width: 46vw;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    
    font-size: 20px;
    font-weight: normal;
    margin-top: 0px;
    border-radius: 5px;
    padding: 0px 20px 20px 20px;

}
.hero-btns {
    margin-top: 20px;
    margin-left: 4%;
}

@media screen and (max-width: 1100px) {
    .hero-container h1 {
        width: 60vw;
        font-size: 40px;
    }
    
}


@media screen and (max-width: 900px) {
    .hero-container h1 {
        width: 70vw;
        font-size: 35px;
    }
    
}



@media screen and (max-width: 800px) {
    .hero-container h1 {
        width: 80vw;
        font-size: 30px;
    }
    .hero-container p {
        font-size: 20px;
        width: 70vw;
    }
    

    .hero-container{
        height: 80vh;
    }

    video{
        display: none;
    }

    .responsive_hero{
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
    }
}


@media screen and (max-width: 490px) {
    .hero-container h1 {
        width: 80vw;
        font-size: 24px;
        padding-right: 0px;
    }

    .hero-container p {
        font-size: 15px;
    }
    
}


@media screen and (max-width: 400px) {
    .hero-container h1 {
        width: 80vw;
        font-size: 20px;
        padding-right: 0px;
    }
    .hero-container p {
        font-size: 13px;
    }
    
    
}

.hero-btns a {
    color: black;
    text-decoration: none;
    
}

